@charset "utf-8";

/* =========================================================
 clearfix
========================================================= */

.u-float_left {
  float: left;
}

.u-float_right {
  float: right;
}
