@charset "utf-8";

/* =========================================================
 clearfix
========================================================= */

.u-font_mincho {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", serif;
}

.u-font_en_sans_serif {
  font-family: "Roboto Condensed", sans-serif;
}

.u-font_en_serif {
  font-family: "Times New Roman", serif;
}

.u-font_ultra_light {
  font-weight: 100;
}

.u-font_light {
  font-weight: 300;
}

.u-font_normal {
  font-weight: normal;
}

.u-font_bold {
  font-weight: bold;
}

.u-font_sm {
  font-size: 0.8em;
}

.u-font_md {
  font-size: 1.2em;
}

.u-font_lg {
  font-size: 1.4em;
}
