@charset "utf-8";

// ---------------------------------------------------------------
//  keyframes
// ---------------------------------------------------------------

// fade
// --------------------
@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// slide
// --------------------
@keyframes slide_right {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(100%);
	}
}
@keyframes slide_down {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(0);
	}
}
@keyframes slide_up {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-100%);
	}
}

// spin
// --------------------

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
