@charset "utf-8";

/* =========================================================
adsense
========================================================= */

.p-adsense {
  overflow: hidden;
  margin-top: 2em;
  margin-right: auto;
  margin-bottom: 2em;
  margin-left: auto;
  text-align: left;
}

.p-adsense_title {
  font-size: 0.8rem;
  font-weight: normal;
  margin-bottom: 0.5em;
}

.my_adslot {
  width: 320px;
  height: 50px;

  @include addQuery(500px) {
    width: 468px;
    height: 60px;
  }

  @include addQuery(800px) {
    width: 728px;
    height: 90px;
  }
}
