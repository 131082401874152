@charset "utf-8";

/* =========================================================
 fonts
========================================================= */

/* PixelMplus12
--------------------------------------------------------- */
@font-face {
  font-family: 'pixel_mplus_12';
  src: url(assets/font/PixelMplus12-Regular.woff) format("woff");
}

/* Open Sans
--------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

