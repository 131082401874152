@charset "utf-8";

/* =========================================================
 page_loading

 ページのローディング
========================================================= */

@keyframes page_loading_spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#p-page_loading {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: #000;
  transition: all 0.4s ease;

  &.init {
    display: block;
  }

  &.complete {
    opacity: 0;
  }
}

.p-page_loading__logo {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.p-page_loading__loader {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

.p-page_loading__spinner {
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 50%;
    top: -20px;
    left: -20px;
    animation: page_loading_spinner 1s infinite linear;
  }
}
