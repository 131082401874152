@charset "utf-8";

/* =========================================================
 clearfix
========================================================= */

.u-color_text {
  color: #4d4d4d;
}

.u-color_error {
  color: #e26d79;
}

.u-color_link {
  color: #333;
}

.u-color_white {
  color: #fff;
}
