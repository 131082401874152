@charset "utf-8";

/* =========================================================
 base
========================================================= */

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;

}

body {
  color: $COLOR_TEXT;
  font-family: $FONT_FAMILY_GOTHIC;
  font-feature-settings: 'palt' 1;
  font-kerning: auto;
  word-break: break-word;
  letter-spacing: 0.02em;
  line-height: 1.8;
}

a {
  color: $COLOR_LINK;
  text-decoration: none;

  // &:link,
  // &:visited {
  //  color: $COLOR_LINK;
  // }

  &:hover {
    color: $COLOR_LINK;
    text-decoration: underline;
  }
}

p {
  margin-top: 0;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

img,
canvas,
iframe,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
}

ol,
ul {
  padding-left: 2em;
}

small {
  display: inline-block;
}


/* reset
--------------------------------------------------------- */
.ol,
.ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dl,
.dd {
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  font-size: 1em;
  font-weight: inherit;
}

.figure {
  margin: 0;
}

.em {
  font-style: normal;
}

.button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  padding: 0;
}

.label {
  margin-bottom: 0;
}


/*

Railscasts-like style (c) Visoft, Inc. (Damien White)

*/
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #232323;
  color: #e6e1dc;
}

.hljs-comment,
.hljs-quote {
  color: #bc9458;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag {
  color: #c26230;
}

.hljs-string,
.hljs-number,
.hljs-regexp,
.hljs-variable,
.hljs-template-variable {
  color: #a5c261;
}

.hljs-subst {
  color: #519f50;
}

.hljs-tag,
.hljs-name {
  color: #e8bf6a;
}

.hljs-type {
  color: #da4939;
}

.hljs-symbol,
.hljs-bullet,
.hljs-built_in,
.hljs-builtin-name,
.hljs-attr,
.hljs-link {
  color: #6d9cbe;
}

.hljs-params {
  color: #d0d0ff;
}

.hljs-attribute {
  color: #cda869;
}

.hljs-meta {
  color: #9b859d;
}

.hljs-title,
.hljs-section {
  color: #ffc66d;
}

.hljs-addition {
  background-color: #144212;
  color: #e6e1dc;
  display: inline-block;
  width: 100%;
}

.hljs-deletion {
  background-color: #600;
  color: #e6e1dc;
  display: inline-block;
  width: 100%;
}

.hljs-selector-class {
  color: #9b703f;
}

.hljs-selector-id {
  color: #8b98ab;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

